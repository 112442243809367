import { Routes, Route, Link, useParams } from "react-router-dom";
// import Main from "./Pages/Main";
// import SingleChasingShadows from "./Pages/SingleChasingShadows";
// import PreSaveChasingShadows from "./Pages/PreSaveChasingShadows";
// import PreSaveChasingShadowsSpotify from "./Pages/PreSaveChasingShadowsSpotify";
// import PreSaveSerenitySpotify from "./Pages/PreSaveSerenitySpotify";
// import SingleSerenity from "./Pages/SingleSerenity";
// import PreSaveCarelessSpotify from "./Pages/PreSaveCarelessSpotify";
// import SingleCareless from "./Pages/SingleCareless";
// import SingleZero from "./Pages/SingleZero";
// import Admin from "./Pages/Admin";
import { useEffect, useState, lazy, Suspense } from "react";
import Loader from "./components/Loader";
import SingleTriggered from "./Pages/SingleTriggered";

const Epk = lazy(() => import("./Pages/Epk"))
const Login = lazy(() => import("./Pages/Login"))
const Dashboard = lazy(() => import("./Pages/Dashboard"))
const SingleHope = lazy(() => import("./Pages/SingleHope"))
const SingleWinter = lazy(() => import("./Pages/SingleWinter"))

const Main = lazy(() => import("./Pages/Main"))
const SingleChasingShadows = lazy(() => import("./Pages/SingleChasingShadows"))
const PreSaveChasingShadows = lazy(() => import("./Pages/PreSaveChasingShadows"))
const PreSaveChasingShadowsSpotify = lazy(() => import("./Pages/PreSaveChasingShadowsSpotify"))
const PreSaveSerenitySpotify = lazy(() => import("./Pages/PreSaveSerenitySpotify"))
const SingleSerenity = lazy(() => import("./Pages/SingleSerenity"))
const PreSaveCarelessSpotify = lazy(() => import("./Pages/PreSaveCarelessSpotify"))
const SingleCareless = lazy(() => import("./Pages/SingleCareless"))
const SingleZero = lazy(() => import("./Pages/SingleZero"))
const Admin = lazy(() => import("./Pages/Admin"))

function App() {
  const [loggedUser, setLoggedUser] = useState({ logged: false, key: "" })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const key = getCookie("hls-dsh-key");

    if (key) {
      setLoggedUser({ logged: true, key });
    }
  }, []);

  return (
    <div className="App">
      <Loader loading={loading} />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/chasing-shadows" element={<SingleChasingShadows />} />
        <Route path="/chasing-shadows/success" element={<PreSaveChasingShadows success={true} />} />
        <Route path="/epk" element={<Epk />} />
        <Route path="/serenity" element={<SingleSerenity />} />
        <Route path="/zero" element={<SingleZero />} />
        <Route path="/hope" element={<SingleHope />} />
        <Route path="/winter" element={<SingleWinter />} />
        <Route path="/chasing-shadows/spotify" element={<PreSaveChasingShadowsSpotify success={false} />} />
        <Route path="/chasing-shadows/spotify/success" element={<PreSaveChasingShadowsSpotify success={true} />} />
        <Route path="/presave/careless/success" element={<PreSaveCarelessSpotify success={true} />} />
        <Route path="/presave/careless" element={<PreSaveCarelessSpotify success={false} />} />
        <Route path="/careless" element={<SingleCareless />} />
        <Route path="/triggered" element={<SingleTriggered />} />
        <Route path="/serenity/spotify" element={<PreSaveSerenitySpotify success={false} />} />
        <Route path="/serenity/success" element={<PreSaveSerenitySpotify success={true} />} />
        {loggedUser.logged ? <Route path="/hls/admin/aaa" element={<Admin loggedUser={loggedUser} />} /> : <Route path="/hls/admin/aaa" element={<Login setLoggedUser={setLoggedUser} />} />}
        {loggedUser.logged ? <Route path="/hls/dashboard" element={<Dashboard loggedUser={loggedUser} setLoading={setLoading} />} /> : <Route path="/hls/dashboard" element={<Login setLoggedUser={setLoggedUser} setLoading={setLoading} />} />}
        {/* <Route path="/chasing-shadows" element={<PreSaveChasingShadows success={false} />} /> */}
      </Routes>
    </div>
  );
}

export default App;
